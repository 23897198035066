"use client";
import React from "react";
import {useTranslation} from "react-i18next";
import './worldwide.scss';
import {Globe} from "../../ui/globe";
import FlyInAnimation from "../../animations/fly-in.animation";

export function Worldwide() {
  const {t} = useTranslation();

  return (
    <section id="worldwide-section" className="worldwide">
      <div className="worldwide__container">
          <div className="worldwide__copy">
            <FlyInAnimation delay={0.2}>
              <h2 className="worldwide__header">
                {t('worldwide.header')}
              </h2>
            </FlyInAnimation>
            <FlyInAnimation delay={0.4}>
              <p className="worldwide__description">
                {t('worldwide.description')}
              </p>
            </FlyInAnimation>
          </div>
          <div className="worldwide__globe-wrapper"/>
          <div className="worldwide__globe">
            <Globe/>
          </div>
      </div>
    </section>
  );
}

export default Worldwide;