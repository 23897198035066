"use client";
import React, {forwardRef, useRef} from "react";
import {useTranslation} from "react-i18next";
import './requirements-passion.scss';
import {cn} from "../../../lib";
import {AnimatedBeam} from "../../ui";
import {MailIcon, UserIcon} from "lucide-react";
import AppearAnimation from "../../animations/appear.animation";
import FlyInAnimation from "../../animations/fly-in.animation";
import Button from "../../ui/button";

export function RequirementsPassion() {
  const {t} = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);
  const div4Ref = useRef<HTMLDivElement>(null);
  const div5Ref = useRef<HTMLDivElement>(null);
  const div6Ref = useRef<HTMLDivElement>(null);

  return (
    <section id="requirements-passion-section" className="requirements-passion">
      <div className="requirements-passion__container">
        <div className="requirements-passion__links" ref={containerRef}>
          <div className="requirements-passion__links-wrapper">
            <div className="requirements-passion__links-col">
              <Circle ref={div5Ref}>
                <UserIcon color="black"/>
              </Circle>
            </div>
            <div className="requirements-passion__links-col">
              <Circle ref={div6Ref} className="w-14 h-14">
                <img src='/assets/imgs/logo-small-black.svg' alt="orora labs icon"/>
              </Circle>
            </div>
            <div className="requirements-passion__links-col">
              <Circle ref={div1Ref}>
                <img src='/assets/imgs/figma-icon.svg' alt="figma icon"/>
              </Circle>
              <Circle ref={div2Ref}>
                <img src='/assets/imgs/framer-icon.svg' alt="framer icon"/>
              </Circle>
              <Circle ref={div3Ref}>
                <img src='/assets/imgs/microsoft_azure-icon.svg' alt="microsoft azure icon"/>
              </Circle>
              <Circle ref={div4Ref}>
                <img src='/assets/imgs/amazon_aws-icon.svg' alt="amazon aws icon"/>
              </Circle>
            </div>
          </div>
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={div5Ref}
            toRef={div6Ref}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={div1Ref}
            toRef={div6Ref}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={div2Ref}
            toRef={div6Ref}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={div3Ref}
            toRef={div6Ref}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={div4Ref}
            toRef={div6Ref}
          />
          <AnimatedBeam
            containerRef={containerRef}
            fromRef={div5Ref}
            toRef={div6Ref}
          />
        </div>

        <div className="requirements-passion__copy">
          <FlyInAnimation delay={0.2}>
            <h2 className="requirements-passion__header">
              {t('requirementsPassion.header1')}<br/>
              <b>{t('requirementsPassion.header2')}_</b>
            </h2>
          </FlyInAnimation>

          <FlyInAnimation delay={0.3}>
            <p className="requirements-passion__description">
              {t('requirementsPassion.description')}
            </p>
          </FlyInAnimation>

          <FlyInAnimation delay={0.4}>
            <div className="requirements-passion__copy-actions">
              <Button
                onClick={() => {
                  const section = document.getElementById("contact-section");
                  if (section) {
                    section.scrollIntoView({behavior: 'smooth'});
                  }
                }}
                type='submit'
              >
                {t('requirementsPassion.interested')}
              </Button>
            </div>
          </FlyInAnimation>
        </div>
      </div>
    </section>
  );
}

const Circle = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({className, children}, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex size-12 items-center justify-center rounded-full border-2 bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)]",
        className,
      )}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

export default RequirementsPassion;