import { useId, useMemo } from "react";
import { cn } from "../../lib";
import "./dot-pattern.scss";

interface Star {
  x: string;
  y: string;
  twinkle: boolean;
  opacity: number;
  radius: number;
  color: string;
  animationDuration?: number;
  animationDelay?: number;
}

interface DotPatternProps {
  width?: any;
  height?: any;
  numStars?: number;
  className?: string;

  [key: string]: any;
}

export function DotPattern({
                             width = "100%",
                             height = "100%",
                             numStars = 150,
                             className,
                             ...props
                           }: DotPatternProps) {
  const id = useId();

  // Wykrywanie urządzenia mobilnego
  const isMobile = window.innerWidth <= 768; // Możesz dostosować breakpoint

  // Ustaw liczbę gwiazdek w zależności od urządzenia
  const starsCount = isMobile ? Math.floor(numStars / 3) : numStars;

  // Generowanie gwiazdek tylko raz za pomocą useMemo
  const stars: Star[] = useMemo(() => {
    const colors = ["#FFFFFF", "#FFFAF0", "#E0FFFF"];

    return Array.from({ length: starsCount }, () => {
      // Wyłącz animacje na urządzeniach mobilnych
      const twinkle = !isMobile && Math.random() < 0.7;
      return {
        x: Math.random() * 100 + "%",
        y: Math.random() * 100 + "%",
        twinkle: twinkle,
        opacity: Math.random() * 0.5 + 0.5,
        radius: Math.random() * 1.5 + 0.5,
        color: colors[Math.floor(Math.random() * colors.length)],
        animationDuration: twinkle ? Math.random() * 3 + 2 : undefined,
        animationDelay: twinkle ? Math.random() * 5 : undefined,
      };
    });
  }, [starsCount, isMobile]);

  // Jeśli jest urządzenie mobilne i chcesz całkowicie usunąć tło
  // if (isMobile) {
  //   return null;
  // }

  return (
    <svg
      aria-hidden="true"
      className={cn(
        "pointer-events-none absolute inset-0 h-full w-full",
        className
      )}
      width={width}
      height={height}
      {...props}
    >
      <defs>
        <mask id={`${id}-mask`}>
          <linearGradient
            id={`${id}-gradient`}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="white" />
            <stop offset="25%" stopColor="transparent" />
            <stop offset="75%" stopColor="transparent" />
            <stop offset="100%" stopColor="white" />
          </linearGradient>
          <rect width="100%" height="100%" fill={`url(#${id}-gradient)`} />
        </mask>
      </defs>
      <g mask={`url(#${id}-mask)`}>
        {stars.map((star, index) => (
          <circle
            key={index}
            cx={star.x}
            cy={star.y}
            r={star.radius}
            className={star.twinkle ? "dot-twinkle" : "dot-static"}
            style={{
              opacity: star.opacity,
              fill: star.color,
              animationDuration: star.animationDuration
                ? `${star.animationDuration}s`
                : undefined,
              animationDelay: star.animationDelay
                ? `${star.animationDelay}s`
                : undefined,
            }}
          />
        ))}
      </g>
    </svg>
  );
}
