import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="flex justify-center">
      <label className="swap swap-rotate">
        <input
          type="checkbox"
          className="language-controller"
          checked={i18n.language === 'pl'}
          onChange={() => changeLanguage(i18n.language === 'en' ? 'pl' : 'en')}
        />

        <svg
          className="swap-on h-6 w-6"
          enableBackground="new 0 0 512 512"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="256" cy="256" fill="#f0f0f0" r="256"/>
          <path d="m512 256c0 141.384-114.616 256-256 256s-256-114.616-256-256" fill="#d80027"/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
        </svg>

        <svg
          className="swap-off h-6 w-6"
          enableBackground="new 0 0 512 512"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="256" cy="256" fill="#f0f0f0" r="256"/>
          <g fill="#d80027">
            <path d="m244.87 256h267.13c0-23.106-3.08-45.49-8.819-66.783h-258.311z"/>
            <path d="m244.87 122.435h229.556c-15.671-25.572-35.708-48.175-59.07-66.783h-170.486z"/>
            <path
              d="m256 512c60.249 0 115.626-20.824 159.356-55.652h-318.712c43.73 34.828 99.107 55.652 159.356 55.652z"/>
            <path
              d="m37.574 389.565h436.852c12.581-20.529 22.338-42.969 28.755-66.783h-494.362c6.417 23.814 16.174 46.254 28.755 66.783z"/>
          </g>
          <path
            d="m118.584 39.978h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037c-19.106 15.915-35.852 34.561-49.652 55.337h7.475l-13.813 10.035c-2.152 3.59-4.216 7.237-6.194 10.938l6.596 20.301-12.306-8.941c-3.059 6.481-5.857 13.108-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444c-1.301 10.458-1.979 21.11-1.979 31.921h256c0-141.384 0-158.052 0-256-50.572 0-97.715 14.67-137.416 39.978zm9.918 190.422-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm100.115 100.083-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm0-74.574 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822z"
            fill="#0052b4"/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
          <g/>
        </svg>
      </label>
    </div>
  );
};

export default LanguageSwitcher;
