import React, {useState} from 'react';
import './contact.scss';
import {useTranslation} from "react-i18next";
import {cn, useTheme} from "../../../lib";
import {Input, Label, TextArea} from '../../ui';
import {CheckCircleIcon, MailIcon} from "lucide-react";
import AppearAnimation from "../../animations/appear.animation";
import FlyInAnimation from "../../animations/fly-in.animation";
import Button from "../../ui/button";
import {useFormspark} from "@formspark/use-formspark";

const FORMSPARK_FORM_ID = "FQ2KUR2Wh"

interface ContactProps {
}

const Contact: React.FC<ContactProps> = () => {
  const {theme} = useTheme();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    await submit({
      name: name,
      email: email,
      message: message,
      _botpoison: "pk_2975b0e4-994e-416f-bd85-2a255b1e2b77"
    });

    setName('');
    setEmail('');
    setMessage('');

    (document.getElementById("success_dialog") as HTMLDialogElement)?.showModal();

    setIsLoading(false);
  }

  return (
    <section id="contact-section" className="contact">
      <div className="contact__container">
        <div className="contact__information">
          <FlyInAnimation delay={0.1}>
            <div className="contact__reminder">
              <h3>{t('contact.gotAQuestion')}<br/> <b>{t('contact.weAreHereForYou')}_</b></h3>
            </div>
          </FlyInAnimation>

          <div className="contact__direct-info">
            <AppearAnimation delay={0.4}>
              <div className="contact__direct-info-col">
                <h4>{t('contact.emailAddress')}</h4>
                <span>{t('companyEmail')}</span>
              </div>
            </AppearAnimation>

            <AppearAnimation delay={0.4}>
              <div className="contact__direct-info-col">
                <h4>{t('contact.phoneNumber')}</h4>
                <span>{t('companyPhone')}</span>
              </div>
            </AppearAnimation>
          </div>
        </div>

        <form onSubmit={onSubmit} className="contact__form">
          <div className="contact__form-content">
            <span>{t('contact.orUseThisForm')}</span>

            <input type="hidden" name={theme === 'dark' ? "_feedback.dark" : ''} value="true"/>

            <input type="hidden" name="_feedback.success.title" value={t('contact.feedback.successTitle')}/>
            <input type="hidden" name="_feedback.success.message" value={t('contact.feedback.successMessage')}/>

            <input type="hidden" name="_feedback.error.title" value={t('contact.feedback.errorTitle')}/>
            <input type="hidden" name="_feedback.error.message" value={t('contact.feedback.errorMessage')}/>

            <input type="hidden" name="botpoison-token"/>

            <LabelInputContainer>
              <Label htmlFor="name">{t('contact.name')}</Label>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder={t('contact.name')}
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </LabelInputContainer>

            <LabelInputContainer>
              <Label htmlFor="email">{t('contact.email')}</Label>
              <Input
                type="email"
                id="email"
                name="email"
                placeholder={t('contact.email')}
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </LabelInputContainer>

            <LabelInputContainer>
              <Label htmlFor="message">{t('contact.message')}</Label>
              <TextArea
                id="message"
                name="message"
                placeholder={t('contact.messagePlaceholder')}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </LabelInputContainer>

            <Button
              isLoading={isLoading}
              disabled={isLoading}
              type='submit'
            >
              {t('contact.send')}
              <MailIcon size="18px"/>
            </Button>
          </div>

          <span className="contact__form-legal">{t('contact.dataLegalNotice')}</span>
        </form>

        <Modal
          id="success_dialog"
          header={t('contact.feedback.successTitle')}
          content={t('contact.feedback.successMessage')}
        />
      </div>
    </section>
  );
};

export default Contact;

const LabelInputContainer = ({children, className}: { children: React.ReactNode; className?: string }) => {
  return <div className={cn("flex flex-col space-y-2 w-full", className)}>{children}</div>;
};

const Modal = ({id, header, content}: {
  id: string,
  header: string,
  content: string,
}) => {
  return (
    <dialog id={id} className="modal">
      <div className="modal-box">
        <CheckCircleIcon color="#00FF00" size="64px" className="mx-auto"/>

        <h3 className="text-lg font-bold mt-6 text-center">{header}</h3>
        <p className="py-4 text-center">{content}</p>
        <div className="modal-action">
          <form method="dialog" className="w-full">
            <button className="btn w-full">OK</button>
          </form>
        </div>
      </div>
    </dialog>
  );
};
