import React, {useState} from "react";
import {motion} from "framer-motion";

export const ReplaceLink = (
  {
    initialText,
    hoverText,
    href,
    className,
    width = "auto",
    height = "32px",
    target,
  }: {
    initialText: string;
    hoverText: string;
    href: string;
    className?: string;
    width?: string;
    height?: string;
    target?: string;
  }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a
      href={href}
      className={`relative inline-block overflow-hidden ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        display: "inline-block",
        width,
        height,
      }}
      target={target}
    >
      <motion.span
        className="absolute left-0 w-full"
        initial={{y: 0}}
        animate={{y: isHovered ? "-100%" : "0%"}}
        transition={{duration: 0.3}}
        style={{display: "block"}}
      >
        {initialText}
      </motion.span>

      {/* Hover text animation */}
      <motion.span
        className="absolute left-0 w-full"
        initial={{y: "100%"}}
        animate={{y: isHovered ? "0%" : "100%"}}
        transition={{duration: 0.3}}
        style={{display: "block"}}
      >
        {hoverText}
      </motion.span>
    </a>
  );
};

export default ReplaceLink;
