import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) // Loads translations from JSON files
  .use(LanguageDetector) // Detects user language
  .use(initReactI18next) // Connects i18next with React
  .init({
    fallbackLng: 'en', // Default language
    debug: true,
    backend: {
      loadPath: '/locales/{{lng}}/translation.json' // Path to translation files
    },
    interpolation: {
      escapeValue: false, // React already escapes
    }
  });

export default i18n;
