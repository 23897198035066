import React, {ReactNode, MouseEventHandler} from 'react';
import Loader from "./loader";
import {cn} from "../../lib";
import './button.scss';

interface ButtonProps {
  isLoading?: boolean;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type: "button" | "submit" | "reset" | undefined;
}

const Button: React.FC<ButtonProps> = (
  {
    isLoading = false,
    children,
    onClick,
    disabled = false,
    type = 'button',
  }) => {
  return (
    <button
      className={cn(
        "font-semibold bg-gradient-to-br relative group/btn from-zinc-800 dark:from-zinc-950 dark:to-zinc-800 to-zinc-600 block dark:bg-zinc-800 w-full text-slate-50 rounded-md h-10 shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]",
        {"opacity-50 cursor-not-allowed": isLoading || disabled}
      )}
      type={type}
      onClick={onClick}
      disabled={isLoading || disabled}
    >
      <div className="button__content">
        {isLoading ? (
          <Loader/>
        ) : (
          children
        )}
      </div>
      <BottomGradient/>
    </button>
  );
};

export default Button;

const BottomGradient = () => {
  return (
    <>
      <span
        className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-violet-400 to-transparent"/>
      <span
        className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent"/>
    </>
  );
};

