import React, { useEffect, useRef } from 'react';
import './hero.scss';
import { AuroraBackground, TypewriterWords } from "../../ui";
import FlyInAnimation from "../../animations/fly-in.animation";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../lib";

interface HeroProps {}

const Hero: React.FC<HeroProps> = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const headerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const rootElement = document.documentElement;
    if (theme === 'light') {
      rootElement.classList.remove('dark');
    } else {
      rootElement.classList.add('dark');
    }
  }, [theme]);

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (headerRef.current) {
            const scrollY = window.scrollY;
            headerRef.current.style.transform = `translateY(${scrollY * 0.2}px)`;
          }
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    // Wywołaj handleScroll od razu, aby ustawić początkową pozycję
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const experienceWords = [
    t("hero.forYou"),
    t("hero.online"),
    t("hero.modern"),
    t("hero.better"),
    t("hero.faster")
  ];

  return (
    <AuroraBackground>
      <header className="hero" ref={headerRef}>
        <FlyInAnimation direction="bottom" delay={0.2}>
          <div className="hero__available">
            <div className="hero__available-circle"></div>
            <p className="hero__available-text">
              {t('hero.available')}
            </p>
          </div>
        </FlyInAnimation>

        <FlyInAnimation>
          <div className="hero__container">
            <img
              src={theme === 'dark' ? '/logo-white-full.svg' : '/logo-black-full.svg'}
              className="hero__logo"
              alt="orora labs logo"
            />
          </div>
        </FlyInAnimation>

        <FlyInAnimation direction="bottom" delay={0.4}>
          <div className="hero__experience">
            <div className="hero__experience-text">
              <span>
                {t('hero.creatingThings')}
                <TypewriterWords words={experienceWords} width={130} />
              </span>
            </div>
          </div>
        </FlyInAnimation>
      </header>
    </AuroraBackground>
  );
};

export default Hero;
