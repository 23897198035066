import React from 'react';
import './footer.scss';
import {ReplaceLink} from "../../ui";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../../lib";
import LanguageSwitcher from "../../ui/language-switcher";
import ThemeSwitcher from "../../ui/theme-switcher";

interface FooterProps {
}

const Footer: React.FC<FooterProps> = () => {
  const {theme} = useTheme();
  const {t} = useTranslation();

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__content">
          <div className="footer__description">
            <h3>{t('footer.letsCreateSomething')}<br/> <b>{t('footer.uniqueTogether')}_</b></h3>
            <img
              src={theme === 'dark' ? '/logo-white-full.svg' : '/logo-black-full.svg'}
              className="hero__logo"
              alt="orora labs logo"
            />
          </div>

          <div className="footer__links">
            <div className="footer__links-col footer__links-col--site-map">
              <h4>{t('footer.pages')}</h4>
              <ReplaceLink
                href="/"
                hoverText={t('footer.landing')}
                initialText={t('footer.landing')}
                width="100px"
              />
              <ReplaceLink
                href="/404"
                hoverText="404"
                initialText="404"
                width="100px"
              />
            </div>
            <div className="footer__links-col footer__links-col--socials">
              <h4>{t('footer.socials')}</h4>
              <ReplaceLink
                href="https://www.linkedin.com/company/orora-labs"
                hoverText="LinkedIn"
                initialText="LinkedIn"
                width="100px"
                target="_blank"
              />
            </div>
            <div className="footer__links-col footer__links-col--site-map">
              <h4>{t('footer.contact')}</h4>
              <span>biuro@ororalabs.pl</span>
              <span>+48 504 988 274</span>
            </div>
          </div>
        </div>
        <div className="footer__copyright">
          <span className="footer__copyright-disclaimer">
              {t('footer.handmadeBy')} <b><a href="https://ororalabs.pl"
                                className="footer__copyright-disclaimer-link">ororalabs.pl</a></b> {t('footer.in')} <b>{t('footer.poland')}</b>
          </span>

          <div className="footer__switchers">
            <LanguageSwitcher/>
            <ThemeSwitcher/>
          </div>

          <span className="footer__copyright-text">
            © {new Date().getFullYear()} {t('footer.allRightsReserved')}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
