import './app.scss';
import Hero from './components/sections/hero/hero';
import {Helmet, HelmetProvider} from "react-helmet-async";
import Footer from "./components/sections/footer/footer";
import Contact from "./components/sections/contact/contact";
import {DotPattern, Worldwide} from "./components";
import RequirementsPassion from "./components/sections/requirements-passion/requirements-passion";
import {cn} from "./lib";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Orora Labs</title>
        <meta name="description" content="Orora Labs"/>
        <meta name="keywords" content="Orora Labs"/>
      </Helmet>

      <div className="app">
        <Hero/>

        <div className="app__body">
          <RequirementsPassion/>
         <Worldwide/>
          <Contact/>
          <DotPattern
            width={10}
            height={10}
            cx={1}
            cy={1}
            cr={1}
            className="mask-[linear-gradient(to_bottom_right,white,transparent,transparent)]"
          />
        </div>

        <Footer />

      </div>
    </HelmetProvider>
  );
}

export default App;
