import React, {ReactNode, useEffect} from 'react';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';

const directionVariants = {
  top: {y: '-100%', opacity: 0},
  bottom: {y: '100%', opacity: 0},
  left: {x: '-100%', opacity: 0},
  right: {x: '100%', opacity: 0},
  visible: {x: 0, y: 0, opacity: 1},
};

interface FlyInAnimationProps {
  children: ReactNode;
  direction?: 'top' | 'bottom' | 'left' | 'right';
  delay?: number;
  threshold?: number;
}

const FlyInAnimation: React.FC<FlyInAnimationProps> = (
  {
    children,
    direction = 'bottom',
    delay = 0,
    threshold = 0.1,
  }) => {
  const controls = useAnimation();
  const {ref, inView} = useInView({
    threshold: threshold,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={directionVariants[direction]}
      animate={controls}
      transition={{
        duration: 1,
        delay: delay,
        ease: [0, 0.33, 0, 1],
      }}
      variants={directionVariants}
    >
      {children}
    </motion.div>
  );
};

export default FlyInAnimation;
