"use client";

import createGlobe, { COBEOptions } from "cobe";
import { useEffect, useRef, useState } from "react";
import { useSpring } from "react-spring";
import { cn, useTheme } from "../../lib";

const GLOBE_CONFIG: COBEOptions = {
  width: 800,
  height: 800,
  onRender: () => {},
  devicePixelRatio: 2,
  phi: 0,
  theta: 0.3,
  dark: 0,
  diffuse: 0.4,
  mapSamples: 16000,
  mapBrightness: 1.2,
  baseColor: [1, 1, 1],
  markerColor: [117 / 255, 99 / 255, 130 / 255],
  glowColor: [1, 1, 1],
  markers: [
    { location: [53.3367, 15.0496], size: 0.05 },
    { location: [52.2297, 21.0122], size: 0.07 },
    { location: [40.0583, -74.4057], size: 0.08 },
    { location: [25.276987, 55.296249], size: 0.07 },
    { location: [19.4326, -99.1332], size: 0.05 },
    { location: [14.5995, 120.9842], size: 0.03 },
    { location: [19.076, 72.8777], size: 0.06 },
    { location: [30.0444, 31.2357], size: 0.07 },
    { location: [39.9042, 116.4074], size: 0.08 },
    { location: [-23.5505, -46.6333], size: 0.05 },
    { location: [19.4326, -99.1332], size: 0.06 },
    { location: [34.6937, 135.5022], size: 0.05 },
    { location: [41.0082, 28.9784], size: 0.06 },
  ],
};

export function Globe({
                        className,
                        config = GLOBE_CONFIG,
                      }: {
  className?: string;
  config?: COBEOptions;
}) {
  const { theme } = useTheme();

  let width = 0;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const pointerInteracting = useRef<number | null>(null);
  const pointerInteractionMovement = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  // react-spring for smooth rotation
  const [{ r }, api] = useSpring(() => ({
    r: 0,
    config: { mass: 1, tension: 280, friction: 40, precision: 0.001 },
  }));

  // Colors based on theme
  const globeColor: [number, number, number] =
    theme === "dark" ? [0.2, 0.2, 0.2] : [1, 1, 1];
  const glowColor: [number, number, number] =
    theme === "dark" ? [0.4, 0.4, 0.4] : [0.8, 0.8, 0.8];

  const onResize = () => {
    if (canvasRef.current) {
      width = canvasRef.current.offsetWidth;
    }
    setIsMobile(window.innerWidth <= 768);
  };

  // Handling globe interaction (dragging)
  const updatePointerInteraction = (value: number | null) => {
    pointerInteracting.current = value;
    if (canvasRef.current) {
      canvasRef.current.style.cursor = value ? "grabbing" : "grab";
    }
  };

  const updateMovement = (clientX: number) => {
    if (pointerInteracting.current !== null) {
      const delta = clientX - pointerInteracting.current;
      pointerInteractionMovement.current = delta;
      api.start({ r: delta / 200 }); // Start the spring-based animation
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    /*if (isMobile) return;*/

    let phi = 0;
    const globe = createGlobe(canvasRef.current!, {
      ...config,
      baseColor: globeColor,
      glowColor: glowColor,
      width: width * 2,
      height: width * 2,
      onRender: (state: Record<string, any>) => {
        // If user isn't dragging, apply automatic rotation
        if (!pointerInteracting.current) {
          phi += 0.005; // Rotate automatically
        }
        state.phi = phi + r.get(); // Use spring value for rotation
        state.width = width * 2;
        state.height = width * 2;
      },
    });

    setTimeout(() => (canvasRef.current!.style.opacity = "1"), 1000);

    return () => {
      globe.destroy();
      window.removeEventListener("resize", onResize);
    };
  }, [theme, r, isMobile]);

  // if (isMobile) {
  //   return (
  //     <div
  //       className={cn(
  //         "absolute inset-0 mx-auto aspect-[1/1] w-full max-w-[600px]",
  //         className
  //       )}
  //     >
  //       <img
  //         src="/assets/imgs/globe.png"
  //         alt="Globe"
  //         className="w-full h-auto"
  //       />
  //     </div>
  //   );
  // }

  return (
    <div
      className={cn(
        "absolute inset-0 mx-auto aspect-[1/1] w-full max-w-[600px]",
        className
      )}
    >
      <canvas
        className={cn(
          "size-full opacity-0 transition-opacity duration-500 [contain:layout_paint_size]"
        )}
        ref={canvasRef}
        onPointerDown={(e) => {
          updatePointerInteraction(
            e.clientX - pointerInteractionMovement.current
          );
        }}
        onPointerUp={() => updatePointerInteraction(null)}
        onPointerOut={() => updatePointerInteraction(null)}
        onMouseMove={(e) => updateMovement(e.clientX)}
        onTouchMove={(e) => {
          e.touches[0] && updateMovement(e.touches[0].clientX);
        }}
      />
    </div>
  );
}
