import React, { ReactNode, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const appearVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

interface AppearAnimationProps {
  children: ReactNode;
  delay?: number;
  threshold?: number;
}

const AppearAnimation: React.FC<AppearAnimationProps> = (
  {
    children,
    delay = 0,
    threshold = 0.1,
  }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: threshold,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      transition={{
        duration: 2,
        delay: delay,
        ease: [0, 0.33, 0, 1],
      }}
      variants={appearVariants}
    >
      {children}
    </motion.div>
  );
};

export default AppearAnimation;
